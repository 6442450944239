// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
    color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
    &.vertScroll .ps__thumb-x {
        display: none;
    }
}

.MuiModal-root {
    .MuiDialogContentText-root {
        font-size: 16px;
    }
}

.fancybox__container {
    z-index: 9999 !important;
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

.image-gallery {
    width: 90%;
    height: auto;
}

.image-gallery-slide img {
    width: 90%;
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}

.fullscreen .image-gallery-slide img {
    max-height: 100vh;
}

.MuiGrid-item {
    // z-index: 1;
}

.family-grid {
    .img-wrap {
        // padding-top: 56.25%;
        padding-top: 85%;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        // img {
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     object-fit: cover;
        // }
    }
}

.family-tree {
    position: relative;
    margin: 38px -8px 0 !important;
    // width: 100%;
    width: auto;
    align-items: flex-start;

    &.sub-child {
        &:before {
            width: 50%;
            margin-left: 1px;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        transform: translateX(-50%);
        height: 1px;
        background: #bcb1a1;
        margin: 0 0 0 -8px;

        @media screen and (min-width: 768px) {
            width: 49%;
        }

        @media screen and (min-width: 1200px) {
            width: 48%;
        }

        @media screen and (min-width: 1440px) {
            width: 49.2%;
            margin-left: -7px;
        }
    }

    &:after {
        content: '';
        position: absolute;
        top: -50px;
        left: 50%;
        width: 1px;
        transform: translateX(-50%);
        height: 50px;
        background: #bcb1a1;
        // z-index: -1;
    }

    > .MuiGrid-item {
        position: relative;
        margin-top: 26px;
        padding: 0 8px !important;

        &:before {
            content: '';
            position: absolute;
            height: 26px;
            width: 1px;
            top: -26px;
            left: 50%;
            background: #bcb1a1;
        }
    }

    // .img-wrap {
    //     padding-top: 56.25%;
    //     position: relative;
    //     border-radius: 8px;
    //     overflow: hidden;
    //     img {
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         width: 100%;
    //         height: 100%;
    //         object-fit: cover;
    //     }
    // }

    // img {
    //     vertical-align: top;
    //     border-radius: 8px;
    // }
}

.grid-order1 {
    .img-wrap {
        padding-top: 62%;
    }
}

.grid-order3 {
    margin-left: -8px !important;
    margin-right: -8px !important;

    > .MuiGrid-item {
        padding: 0 8px !important;
    }

    .MuiGrid-container {
        margin-left: -5px !important;
        margin-right: -5px !important;

        > .MuiGrid-item {
            padding: 0 5px !important;
        }
    }
}

.GenderSelectPlaceholder:after {
    content: 'Select';
    position: absolute;
    left: 15px;
    top: 40%;
    font-size: 14px;
    font-weight: 400;
    color: rgba(106, 87, 87, 0.7);
    // font-family: Plus Jakarta Sans;
}

.media-lists {
    > div {
        &:first-child {
            @media screen and (min-width: 768px) {
                max-width: 40% !important;
                flex-basis: 40%;
            }
        }

        &:nth-child(2) {
            @media screen and (min-width: 768px) {
                max-width: 60% !important;
                flex-basis: 60%;
            }
        }
    }
    .media-first {
        // padding-top: 107%;
        padding-top: 94%;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px dashed #bcb1a1;
            border-radius: 12px;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .media-sub-images {
        .MuiImageList-root {
            width: auto !important;
            height: auto;
        }
        ul {
            margin: 0;
            li {
                padding: 0 4px;
                height: auto !important;
                margin-bottom: 3px;
                > div {
                    position: relative;
                    // padding-top: 100%;
                    // padding-top: 75%;
                    // padding-top: 76.4%;
                    padding-top: 94%;
                    width: 100%;
                    border-radius: 8px;
                    overflow: hidden;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: 1px dashed #bcb1a1;
                        border-radius: 8px;
                    }
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

.MuiMenu-list {
    padding: 6px 0;
    li {
        font-size: 14px;
    }
}

.img-wrap {
    padding-top: 67.19%;
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .MuiIconButton-root {
        border: 1px solid #bcb1a1;
        background: rgba(188, 177, 161, 0.5);
        position: absolute;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 41px;
        height: 41px;

        svg {
            font-size: 24px;
            path {
                fill: #fff;
            }
        }
    }
}

.video-wrap {
    .MuiIconButton-root {
        border: 1px solid #bcb1a1;
        background: rgba(188, 177, 161, 0.5);
        position: absolute;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 41px;
        height: 41px;

        svg {
            font-size: 24px;
            path {
                fill: #fff;
            }
        }
    }
}

.media-upload {
    .video-wrap {
        height: 345px;
        border-radius: 12px;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.video-play {
    .MuiPaper-root {
        // max-width: 1200px;

        max-width: 800px;
    }
    .upload-video-wrap {
        position: relative;
        padding-top: 56.25%;

        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .MuiButtonBase-root {
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
}

.image-grid {
    gap: 11px !important;
    max-width: 523px;
    width: 100% !important;
}

.react-international-phone-input-container {
    // background-color: #f6f4ef !important;
    border-radius: 14px;
    font-size: 1rem;
    color: #6a5757 !important;
    overflow: hidden;
    .react-international-phone-country-selector {
        background-color: #f6f4ef !important;
        position: static;
    }
    .react-international-phone-country-selector-button {
        background-color: #f6f4ef !important;
        height: 56px;
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
        // border-color: rgb(106, 87, 87);
    }
    .react-international-phone-input {
        background-color: #f6f4ef !important;
        height: 56px;
        border-top-right-radius: 14px;
        border-bottom-right-radius: 14px;
        font-size: 1rem;
        width: 100%;
        // border-color: rgb(106, 87, 87);
    }
}
.react-international-phone-country-selector-dropdown {
    z-index: 2;
}

.slick-dots {
    li {
        width: 10px;
        height: 10px;
        margin: 0 4px;
        transition: 0.3s ease;
        &:hover,
        &.slick-active {
            button {
                background: #cb3e19;
            }
        }
        button {
            width: 10px;
            height: 10px;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 50%;

            &:before {
                display: none;
            }
        }
    }
}

.slick-prev {
    z-index: 1;
    left: 20px;
}

.slick-next {
    right: 20px;
}

.animal-detail-slider {
    margin-bottom: 20px;
    .slick-slide {
        border-radius: 8px;
        overflow: hidden;
    }
    .slick-dots {
        bottom: 80px;
    }

    .img-wrap {
        // padding-top: 56.25%;
        padding-top: 102.883%;
        position: relative;

        @media screen and (min-width: 1600px) {
            padding-top: 90%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.animal-detail-slider-nav {
    .slick-list {
        margin: 0 -7px;
    }
    .slick-slide {
        padding: 0 7px;
    }
    .img-wrap {
        padding-top: 96.81%;
        position: relative;
        border-radius: 8px;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.location-accordion {
    & + .location-accordion {
        border-top: 1px solid #e9e1d5;
        margin-top: 0 !important;
    }
    .MuiAccordionSummary-content {
        margin: 0;
    }

    .infinite-scroll-component__outerdiv {
        height: auto;
    }

    .infinite-scroll-component {
        padding-left: 20px;

        @media screen and (min-width: 1024px) {
            padding-left: 32px;
        }
    }

    .suburbs-gap {
        margin-bottom: 12px;
    }
}

.css-nen11g-MuiStack-root::-webkit-scrollbar-thumb {
    height: 20px;
    background-color: #888;
    border-radius: 3px;
}
.MuiSnackbar-root.animal-alert {
    @media screen and (min-width: 600px) {
        left: 50%;
        transform: translateX(-50%);
    }
}

.photo-slider-grid {
    .MuiGrid-container {
        margin: 0 -10px;
        width: auto;
    }

    .MuiGrid-item {
        padding: 0 10px;
        margin-bottom: 40px;

        img {
            width: 100%;
        }
    }

    .slick-dots {
        bottom: 75px;
    }
}
.animal-filter {
    .MuiInputBase-root .MuiInputBase-input {
        font-size: 16px;

        &::placeholder {
            font-size: 16px;
        }
    }
    .MuiDialogContent-root {
        h3 {
            font-size: 18px;
        }
    }

    .MuiFormControlLabel-root {
        .MuiFormControlLabel-label {
            font-size: 12px;
        }
    }
}

.image-with-caption {
    position: relative;

    // padding-top: 48.7%;
    padding-top: 90%;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .sliderCaption {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        padding: 4px 8px;
        font-size: 14px;
        line-height: 1.428571429;
        font-weight: 600;
        color: #45110b;
        border-top-right-radius: 8px;
        background: rgba(233, 225, 213, 0.9);
        width: auto;
    }
}

.animal-detail-tabs {
    .MuiTab-root {
        width: 50%;
        margin-right: 0;
    }
}

.select-animal {
    display: flex;
    padding: 8px 0;
    border-top: 1px solid #e9e1d5;
    color: #6a5757;
    margin: 0;
    align-items: center;
    .MuiAvatar-square {
        margin-right: 8px;
    }
    img {
        border-radius: 8px;
    }
}

.report-lists {
    li {
        padding: 0;
        text-align: right;
        margin-bottom: 8px;
        justify-content: flex-end;

        a {
            color: #cb3e19;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.734693878;
            text-decoration: underline;
            // text-align: right;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.color-red {
    color: #45110b !important;
}

.h4 {
    font-size: 18px;
    line-height: 1.222223;
    font-weight: 700;
}

.extra-lists {
    width: 100%;
    li {
        padding: 0;
        margin-bottom: 5px;

        a {
            color: #cb3e19;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.734693878;
            // text-decoration: underline;
            // text-align: right;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .MuiIconButton-root {
        &:hover {
            background-color: #cb3e19;
        }
    }
}

.add-field {
    position: relative;

    .close {
        position: absolute;
        top: -5px;
        right: 0;
        padding: 0;
        min-width: 24px;
        min-height: 24px;

        &:after {
            display: none;
        }
    }

    .MuiFormControl-root {
        width: 100%;
    }
}

.add-support-file {
    // width: 100%;
    color: #cb3e19;
    padding-left: 4px !important;
    .MuiSvgIcon-root {
        path {
            fill: #cb3e19;
        }
    }
}

.login {
    .MuiPaper-elevation {
        @media (min-width: 900px) {
            margin-top: 90px;
        }
    }
}

.location-accordion {
    .MuiFormControlLabel-label {
        font-size: 12px;
    }
}

.MuiFormControlLabel-label {
    font-size: 12px;
}

.MuiDialogContent-root {
    .app-filter-typography {
        font-size: 18px;
    }
}

.MuiGrid-root {
    .app-user-credit {
        margin-left: 0px;
    }
}

.enquiry-content {
    .MuiCardContent-root {
        padding: 0 20px 20px;
    }
}
